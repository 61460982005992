import { InputLabel, makeStyles } from "@material-ui/core"
import React from "react"
import Select from "react-select"

const useStyle = makeStyles(theme => ({
  root: {
    marginLeft: "5px",
    padding: "5px",
    display: "flex",
    flexDirection: "row",
    paddingRight: theme.spacing(12),
    marginTop: "5px",
  },
}))

function DrawerWithSelectFlag({
  placeHolder,
  options,
  label,
  onChange,
  value,
  errors,
  getOptionLabel,
  getOptionValue,
  components,
}) {
  const classes = useStyle()

  const style = {
    control: base => ({
      ...base,
      border: 0,
      fontSize: "12px",
      display: "flex",
      flexGrow: 1,
      position: "relative",
      width: "100%",
      // This line disable the blue border
      boxShadow: "none",
      paddingRight: "-10px",
      marginRight: "-90px",
      marginLeft: "-10px",
      borderRadius: 10,
      marginTop: "18px",
      backgroundColor: "hsl(0deg 0% 96%)",
      "&:hover": {
        outline: "none",
      },
      "&:focus": {
        outline: "none",
      },
    }),
    select: {
      backgroundColor: "red",
    },
  }

  const errorStyle = {
    control: base => ({
      ...base,
      border: 0,
      fontSize: "12px",
      display: "flex",
      flexGrow: 1,
      position: "relative",
      width: "100%",
      "&:hover": {
        outline: "none",
      },
      "&:focus": {
        outline: "none",
      },
      // This line disable the blue border
      boxShadow: "none",
      paddingRight: "-10px",
      marginRight: "-90px",
      marginLeft: "-10px",
      borderRadius: 10,
      marginTop: "18px",
      backgroundColor: "hsl(0deg 0% 96%)",
      border: "1.5px solid #FD6E84 ",
    }),
    select: {
      backgroundColor: "red",
    },
  }

  const handleChange = e => {
    onChange(e)
  }

  return (
    <div>
      <InputLabel
        shrink
        style={{ color: "#0A3133", marginTop: "5px", marginBottom: "-15px" }}
      >
        {label}
      </InputLabel>
      <Select
        placeholder={placeHolder}
        value={value}
        options={options}
        className={classes.root}
        isSearchable={true}
        onChange={handleChange}
        getOptionLabel={getOptionLabel}
        getOptionValue={getOptionValue}
        styles={errors ? errorStyle : style}
        components={components}
      />
    </div>
  )
}

export default DrawerWithSelectFlag
